<template>
  <p>Edit the event here</p>
</template>
<script>
export default {
  name: 'Edit',
  props: {
    id: {
      required: true,
      type: Number,
    },
  },
}
</script>

<style scoped></style>
